.SnackbarContainer-top {
  top: 68px !important;
}

.SnackbarItem-variantSuccess {
  background-color: #4db251 !important;
}

.SnackbarItem-variantError {
  background-color: #e74242 !important;
}
